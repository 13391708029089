<template>
  <div id="iphonePreview" class="card-body sticky">
    <h1 class="clock">{{ currentTime | formatTime }}</h1>
    <h5 class="date text-muted">{{ currentTime | formatISODate }}</h5>
    <div class="push">
      <div class="push-header">
        <div class="row">
          <div class="col-1">
            <b-icon-image />
          </div>
          <div class="col-9"><span>{{ profile.customer.companyName }}</span></div>
        </div>
      </div>
      <div class="push-text-content">
        <div class="push-image">
          <b-img :src="content.image.url" v-show="content.image.url" />
        </div>
        <span>{{ content.title }}</span>
        <span>{{ content.subtitle }}</span>
        <span class="text-muted">{{ content.message }}</span>
      </div>
    </div>
    <!-- <div class="application-logo">
      <span v-show="content.badge > 0" class="badge badge-pill badge-danger">{{ content.badge }}</span>
      <b-icon-image />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { now } from '../../utils/date';

export default {
  name: 'PushPreview',
  props: ['content'],
  computed: {
    ...mapGetters(['profile']),
  },
  data: () => ({
    currentTime: now(),
  }),
};
</script>

<style lang="scss" scoped>
#iphonePreview {
  background-image: url('/img/icons/iphone.svg');
  background-repeat: no-repeat;
  background-position: left;
  background-color: rgb(247, 247, 247);
  border-radius: 55px;
  margin: 5px;
  height: 850px;
  width: 420px;

  .clock {
    text-align: center;
    padding-top: 100px;
    position: relative;
    color: #848a93;
  }

  .date {
    text-align: center;
    position: relative;
  }

  .application-logo {
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    padding-left: 10px;

    .badge {
      position: absolute;
      top: -5px;
      left: 47px;
    }

    img {
      width: 50px;
      height: 50px;
    }

    svg {
      width: 50px;
      height: 50px;
    }
  }

  .push {
    border-radius: 10px;
    background-color: #FFF;
    padding: 10px;
    text-align: center;
    width: 360px;
    margin-left: 12px;

    .push-header {
      font-size: 12px;
      display: block;
      text-align: left;
      padding-bottom: 7px;
    }

    .push-text-content {
      font-size: 12px;
      display: block;
      text-align: left;
      padding-bottom: 5px;

      span {
        display: block;
        padding-bottom: 2px;
      }
    }

    p {
      font-size: 11px;
      font-weight: bold;
      text-align: left;

    }

    .push-image {
      img {
        width: 360px;
        display: block;
        height: 100%;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: -10px;
      }
    }

    .push-header {
      img {
        width: 20px;
      }

      svg {
        width: 20px;
        height: 20px;
      }

      span {
        font-weight: bold;
      }
    }
  }

  &.sticky {
    position: sticky;
    top: 10px;
  }
}
</style>
