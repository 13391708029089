<template>
  <div>
    <base-header class="pb-6">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col md="6">
          <card>
            <template #header>
              <h4 class="mb-0">Meta Data</h4>
            </template>
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Platorm
                <div v-if="application(subscriber.aid).applicationType !== 'WEB'">
                  <base-img-icon :svg="application(subscriber.aid).applicationType" />
                  {{ application(subscriber.aid).name }}
                </div>
                <div v-else>
                  <base-img-icon :svg="application(subscriber.aid).applicationType"
                    :title="application(subscriber.aid).name" />
                  <base-img-icon :svg="subscriber.os.toLowerCase()"
                    :title="subscriber.os.replaceAll('%20', ' ') + ' ' + subscriber.osv.replaceAll('%20', ' ')" />
                  <base-img-icon :svg="subscriber.bn.replaceAll('%20', '_').toLowerCase()"
                    :title="subscriber.bn.replaceAll('%20', ' ') + ' ' + subscriber.bv.replaceAll('%20', ' ')" />
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Device
                <b-badge pill>
                  <template v-if="application(subscriber.aid).applicationType !== 'WEB'">
                    {{ subscriber.br }} {{ subscriber.mn }}
                  </template>
                  <template v-else>{{ subscriber.os }} {{ subscriber.osv }}</template>
                </b-badge>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Device Language
                <b-badge pill>{{ subscriber.dl | formatLanguages }}</b-badge>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                External Key
                <b-badge pill>{{ subscriber.ek }}</b-badge>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Email
                <b-badge pill>{{ subscriber.em }}</b-badge>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Phone
                <b-badge pill>{{ subscriber.ph }}</b-badge>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                IP
                <b-badge pill>{{ subscriber.ip }}</b-badge>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Session Count
                <b-badge pill>{{ subscriber.sc | formatNumeric }}</b-badge>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Time Zone
                <b-badge pill>{{ subscriber.tz || 'N/A' }}</b-badge>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                First Seen
                <b-badge pill>{{ subscriber.fs | formatISODateTime }}</b-badge>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                Last Seen
                <b-badge pill>{{ subscriber.ls | formatISODateTime }}</b-badge>
              </b-list-group-item>
            </b-list-group>
          </card>
        </b-col>
        <b-col md="6">
          <card>
            <template #header>
              <h4 class="mb-0">
                Segments
                <b-badge style="font-size: 0.75em;">{{ segments | formatArrayLengthSafe }}</b-badge>
              </h4>
            </template>
            <b-list-group>
              <b-list-group-item v-for="item in segments" :key="item.id">
                {{ item.name }}
              </b-list-group-item>
            </b-list-group>
          </card>
          <card>
            <template #header>
              <h4 class="mb-0">
                Tags
                <b-badge style="font-size: 0.75em;">{{ tags | formatArrayLengthSafe }}</b-badge>
              </h4>
            </template>
            <b-list-group>
              <b-list-group-item v-for="item in tags" :key="item"
                class="d-flex justify-content-between align-items-center">
                {{ item.replace('c_', '') }}
                <b-badge pill>{{ subscriber[item] }}</b-badge>
              </b-list-group-item>
            </b-list-group>
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import memberService from '@/services/members';
import segmentService from '@/services/segment';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb.vue';

export default {
  name: 'SubscriberDetail',
  computed: {
    ...mapGetters(['application']),
    tags() {
      return Object.keys(this.subscriber).filter(a => a.startsWith('c_'));
    },
  },
  components: { RouteBreadCrumb, },
  data: () => ({
    subscriber: {},
    segments: [],
    isWeb: false,
  }),
  created() {
    this.subscriberDetail();
  },
  methods: {
    async subscriberDetail() {
      const subscriber = await memberService.subscriber(this.$route.params.id);
      const segments = await segmentService.getMulti(subscriber.sm);
      this.$set(this, 'subscriber', subscriber);
      this.$set(this, 'segments', segments.results);
    },
  },
};
</script>
